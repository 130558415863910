import React from 'react';

export default function Homepage() {
    return (
        <div className="min-h-screen bg-white">
            {/* Navigation */}
            <nav className="px-6 py-4 flex items-center justify-between bg-white">
                <div>
                    <img src="/asset/tableCheckerLogo.svg" alt="Logo" className="h-8 w-auto" />
                </div>
                    <div className="hidden md:flex items-center space-x-8">
                    <span className="hover:text-[#FF8C00] cursor-pointer">Home</span>
                    <span className="hover:text-[#FF8C00] cursor-pointer">Jobs</span>
                    <span className="hover:text-[#FF8C00] cursor-pointer">Businesses</span>
                    <span className="hover:text-[#FF8C00] cursor-pointer">FAQ</span>
                    <button className="px-4 py-2 text-[#FF8C00] border border-[#FF8C00] rounded hover:bg-[#FF8C00] hover:text-white">
                        Business Log In
                    </button>
                    <button className="px-4 py-2 bg-[#FF8C00] text-white rounded hover:bg-[#FF8C00]/90">
                        Sign Up
                    </button>
                </div>
            </nav>

            {/* Hero Section */}
            <div className="relative bg-[#FFF5EB]">
                <div
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 140, 0, 0.65) 100%), url('/asset/backGroundImage.png')`,
                        borderRadius: '0 0 40px 40px'
                    }}
                />
                <div className="relative max-w-6xl mx-auto px-6 py-16 flex flex-col md:flex-row items-center">
                    <div className="md:w-1/2 mb-8 md:mb-0">
                        <h1 className="text-4xl md:text-5xl font-bold mb-4">
                            Join, Find, Work – <br />
                            Hospitality Jobs Made Simple.
                        </h1>
                        <p className="text-gray-600 mb-8">
                            Connecting Restaurants with Ready-to-Work Talent, instantly.
                        </p>
                        <button className="px-6 py-3 bg-[#FF8C00] text-white rounded-lg hover:bg-[#FF8C00]/90">
                            Register Now
                        </button>
                    </div>
                    <div className="md:w-1/2 flex justify-center">
                        <img src="/asset/mobileExample.png" alt="Mobile App" className="h-96 object-contain relative z-10" />
                    </div>
                </div>
            </div>

            {/* Clients Section */}
            {/* Clients Section */}
            <section className="py-16 px-6">
                <div className="max-w-6xl mx-auto">
                    <div className="text-center mb-12">
                        <h2 className="text-2xl font-bold mb-4">Our Clients</h2>
                        <p className="text-gray-600">
                            We have been working with some Fortune 500+ restaurants
                        </p>
                    </div>
                    <div className="grid grid-cols-3 md:grid-cols-7 gap-8">
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo1.png" alt="Client Logo 1" className="w-20 h-12 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo2.png" alt="Client Logo 2" className="w-20 h-12 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo3.png" alt="Client Logo 3" className="w-20 h-12 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo4.png" alt="Client Logo 4" className="w-20 h-12 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo5.png" alt="Client Logo 5" className="w-20 h-12 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo6.png" alt="Client Logo 6" className="w-20 h-12 object-contain" />
                        </div>
                        <div className="flex items-center justify-center">
                            <img src="/asset/clientLogo3.png" alt="Client Logo 3" className="w-20 h-12 object-contain" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Community Section */}
            <section className="py-16 px-6 bg-white">
                <div className="max-w-6xl mx-auto">
                    <div className="text-center mb-12">
                        <h2 className="text-2xl font-bold">Join our community today</h2>
                        <p className="text-gray-600">Who is Table checker suitable for?</p>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        {[
                            {
                                icon: "/asset/jobSeekerLogo.png",
                                title: "Job seekers",
                                description: "Designed for hospitality workers seeking flexible, short-term gigs, offering insight into workplace reputations and verified feedback to help find ideal shifts at trusted locations."
                            },
                            {
                                icon: "/asset/restaurantLogo.png",
                                title: "Restaurant owners",
                                description: "Perfect for restaurant managers seeking quick, reliable access to quality staff, with centralized reference checks and verified reviews for smarter hiring decisions."
                            },
                            {
                                icon: "/asset/studentLogo.png",
                                title: "Students",
                                description: "Ideal for students looking for flexible, part-time gigs that fit around their schedules. Table Checker offers insight into workplace reputations and verified reviews."
                            }
                        ].map((item, i) => (
                            <div key={i} className="p-6 bg-white rounded-lg shadow-lg text-center">
                                <div className="w-16 h-16 mb-4 bg-[#FFF5EB] rounded-lg p-3 mx-auto">
                                    <img src={item.icon} alt={item.title} className="w-full h-full object-contain" />
                                </div>
                                <h3 className="text-xl font-bold mb-4">{item.title}</h3>
                                <p className="text-gray-600 mb-4">{item.description}</p>
                            </div>
                        ))}
                    </div>
                    <div className="text-center mt-8">
                        <button className="px-6 py-3 bg-[#FF8C00] text-white rounded-lg hover:bg-[#FF8C00]/90">
                            Join in
                        </button>
                    </div>
                </div>
            </section>

            {/* Features Section */}
            <section className="py-16 px-6 bg-[#FFF5EB]">
                <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-12 items-center">
                    <div className="flex justify-center">
                        <img src="/asset/peopleIcon.png" alt="Features" className="rounded-lg" />
                    </div>
                    <div className="text-left">
                        <h2 className="text-3xl font-bold mb-6">
                            Why Table Checker is the best platform for hire and job seeking.
                        </h2>
                        <p className="text-gray-600 mb-8">
                            Table Checker is the ultimate platform for connecting hospitality workers and restaurants, offering verified reviews, streamlined reference management, and tailored job opportunities. Designed for high-turnover industries, it empowers job seekers to find flexible, trusted gigs while enabling managers to hire with confidence. Table Checker is redefining how the hospitality industry works—making hiring and job-seeking simple, reliable, and impactful.                        </p>
                    </div>
                </div>

                <div className="max-w-6xl mx-auto mt-16 grid md:grid-cols-2 gap-8">
                    {[
                        {
                            title: "1. Streamlined Reference Management",
                            description: "Table Checker centralizes reference checks, keeping them confidential until needed, simplifying the hiring process for both managers and workers."
                        },
                        {
                            title: "2. Tailored for High-Turnover Industries",
                            description: "Unlike generic platforms, Table Checker is built specifically for high-turnover sectors, where verified manager feedback is more valuable than traditional resumes."
                        },
                        {
                            title: "3. Lower Turnover Support",
                            description: "By empowering managers with better insights and helping workers find reputable workplaces, Table Checker indirectly contributes to lower turnover rates."
                        },
                        {
                            title: "4. Reliable References",
                            description: "Verified reviews from past managers ensure credible, unbiased feedback, offering a trustworthy alternative to unverified review sites."
                        }
                    ].map((feature, i) => (
                        <div key={i} className="text-left p-6  bg-[#FFD9AB] rounded-lg">
                            <h3 className="text-lg font-bold mb-4">{feature.title}</h3>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            {/* Stats Section */}
            <section className="py-16 px-6 bg-gray-50">
                <div className="max-w-6xl mx-auto">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                        <div className="md:w-1/3 mb-8 md:mb-0">
                            <h2 className="text-left text-4xl font-bold mb-2">
                                Helping local
                            </h2>
                            <h2 className="text-left text-4xl font-bold text-[#FF8C00] mb-4">
                                business and people
                            </h2>
                            <p className="text-left text-gray-600">We reached here with our hard work and dedication</p>
                        </div>
                        <div className="flex flex-col md:flex-row gap-16">
                            <div className="flex items-center gap-4">
                                <img src="/asset/twoPeopleIcon.png" alt="Members" className="w-12 h-12 flex-shrink-0" />
                                <div>
                                    <div className="text-4xl font-bold">2,245,34</div>
                                    <div className="text-gray-600">Members</div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <img src="/asset/fingerIcon.png" alt="Jobs" className="w-12 h-12" />
                                <div>
                                    <div className="text-4xl font-bold">1,828,867</div>
                                    <div className="text-gray-600">Jobs hired</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Testimonials */}
            <section className="py-16 px-6 bg-white">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl font-bold text-center mb-12">
                        What Our Clients Said About Us
                    </h2>
                    <div className="grid md:grid-cols-3 gap-8">
                        <div className="text-left p-6 bg-white rounded-lg shadow-lg">
                            <img
                                src="/asset/AmeliaPicture.png"
                                alt="Amelia Joseph"
                                className="w-16 h-16 rounded-full mb-4"
                            />
                            <h3 className="text-left text-xl font-bold">Amelia Joseph</h3>
                            <p className="text-left text-gray-600 mb-4">Chief Manager</p>
                            <p className="text-left text-gray-600">
                                "Table Checker has revolutionized how we hire staff. The seamless process and verified reviews have made hiring more efficient and trustworthy."
                            </p>
                        </div>
                        <div className="p-6 bg-white rounded-lg shadow-lg">
                            <img
                                src="/asset/JacobPicture.png"
                                alt="Jacob Joshua"
                                className="w-16 h-16 rounded-full mb-4"
                            />
                            <h3 className="text-left text-xl font-bold">Jacob Joshua</h3>
                            <p className="text-left text-gray-600 mb-4">Restaurant Owner</p>
                            <p className="text-left text-gray-600">
                                "Finding skilled workers has never been this easy. Table Checker provides us with great insights and a fantastic pool of applicants."
                            </p>
                        </div>
                        <div className="p-6 bg-white rounded-lg shadow-lg">
                            <img
                                src="/asset/SophiaPicture.png"
                                alt="Sophia Carter"
                                className="w-16 h-16 rounded-full mb-4"
                            />
                            <h3 className="text-left text-xl font-bold">Sophia Carter</h3>
                            <p className="text-left text-gray-600 mb-4">General Manager</p>
                            <p className="text-left text-gray-600">
                                "The platform’s background check and reference features give us the confidence to make the right hiring decisions. Highly recommended!"
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* Footer */}
            <footer className="bg-[#FFF5EB] py-12 px-6">
                <div className="max-w-6xl mx-auto">
                    <div className="grid md:grid-cols-4 gap-8 mb-8">
                        {/* Logo and Button */}
                        <div className="flex flex-col items-center justify-center">
                            <div className="flex items-center mb-4">
                                <span className="text-2xl font-bold">TableChecker</span>
                            </div>
                            <button className="px-6 py-2 bg-[#FF8C00] text-white rounded hover:bg-[#FF8C00]/90">
                                Register now!
                            </button>
                        </div>

                        {/* Product Section */}
                        <div>
                            <h3 className="font-bold mb-4">Product</h3>
                            <div className="space-y-2 text-gray-600">
                                <div>Pricing</div>
                                <div>Sign In</div>
                                <div>Documentation</div>
                            </div>
                        </div>

                        {/* Company Section */}
                        <div>
                            <h3 className="font-bold mb-4">Company</h3>
                            <div className="space-y-2 text-gray-600">
                                <div>About Us</div>
                                <div>Blog</div>
                                <div>Careers</div>
                                <div>Contact Us</div>
                            </div>
                        </div>

                        {/* Legal Section */}
                        <div>
                            <h3 className="font-bold mb-4">Legal</h3>
                            <div className="space-y-2 text-gray-600">
                                <div>Terms & Conditions</div>
                                <div>Privacy Policy</div>
                                <div>Sitemap</div>
                            </div>
                        </div>
                    </div>

                    {/* Copyright Section */}
                    <div className="text-center text-gray-600 text-sm">
                        © Copyright 2024, All Rights Reserved by TableChecker
                    </div>
                </div>
            </footer>
        </div>
    );
}